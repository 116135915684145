import { useState, useEffect } from 'react'
import './App.scss'
import Cookies from 'js-cookie';

import { getWords } from 'api/word'
import { initRound, createRound } from 'api/round'
import styled from "styled-components"

const token = Cookies.get('csrf_token');

const Container = styled('div')`
  flex-wrap: wrap;
  gap: 0px;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
`

const Round = styled('div')`
  flex-grow: 1;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  gap: 4px;

  div {
    align-items: center;
    flex-basis: 0;
    display: flex;
    flex-grow: 1;
    height: 100%;
    text-align: center;
    align-self: center;
    font-size: 30px;
    font-family: arial;
    cursor: pointer;
    transition: .1s;

    @media screen and (min-width: 900px) {
      &:hover {
        font-size: 40px;
      }
    }
  }

  h1 {
    width: 100%;
  }
`

const Words = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
`

const Word = styled('div')`
  text-align: center;
  // width: 100px;
  border: 1px solid #eee;
  padding: 8px;
  display: flex;
  gap: 4px;
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  &-Title {

  }
`

const App = (props) => {
  const [words, setWords] = useState([])
  const [round, setRound] = useState(null)

  useEffect(() => {
    getWords().then(setWords)
    initRound().then(setRound)
  }, [])

  const onClick = (wordId) => {
    createRound({ round: { word_ids: round.attributes.words.map(({ id }) => id), word_id: wordId }}).then((round) => {
      setRound(round)
      getWords().then(setWords)
    })
  }

  return token && (
    <Container>
      <Round>
        {
          round ? (
            round.attributes.words.map(({ id, title }, index) => {
              return (
                <div key={index} onClick={() => onClick(id)}>
                  <h1>
                    {title}
                  </h1>
                </div>
              )
            })
          ) : (
            <div></div>
          )
        }
      </Round>
      <Words>
        {
          words.map(({ title, rounds_count }, index) => {
            return (
              <Word key={index}>
                <div className="Word-Title">
                  {title}
                </div>
                <div className="Word-Rating">
                  {rounds_count}
                </div>
              </Word>
            )
          })
        }
      </Words>
    </Container>
  )
}

export default App
