import axios from 'axios';
import Cookies from 'js-cookie';

import { API_URL } from 'constants'

export function getWord(id) {
  return axios.get(`${API_URL}/api/v1/words/${id}.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return null;
    })
}

export function getWords() {
  return axios.get(`${API_URL}/api/v1/words.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}